import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Login.module.scss';
import CaptchaFrame from './CaptchaFrame';
import Loading from '~/components/Loading';
import { loginUser } from '~/services/auth';
import CusButton from '~/components/CusButton';
import { closeModal } from '~/redux/reducer/module';
import { loginSuccess } from '~/redux/reducer/auth';
import { dispatchPostError } from '~/redux/reducer/error';

const cx = classNames.bind(styles);

function Login() {
    const [uid, setUid] = useState('');
    const [valid, setValid] = useState('');
    const [loading, setLoading] = useState(false);

    const [captcha, setCaptcha] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogin = async () => {
        setLoading(true);
        if (uid.length < 8 || uid.length > 11) {
            setValid('ID người chơi không hợp lệ');
            return setLoading(false);
        }

        const data = {
            app_id: 100067,
            app_server_id: 0,
            login_id: uid,
        };

        const result = await loginUser(data);
        setLoading(false);

        if (!result) {
            setValid('Lỗi máy chủ vui lòng thử lại');
        } else if (result.status === 403) {
            localStorage.removeItem('_re');
            navigate('/');
            window.location.reload();
        } else if (result.status === 401) {
            dispatch(dispatchPostError(result));
        } else if (result.status === 200) {
            dispatch(loginSuccess(result.data));
            dispatch(closeModal());
            document.body.classList.remove('open');
        } else if (result.error === 'invalid_id') {
            setValid('ID người chơi không hợp lệ');
        } else if (result.status === 400) {
            setCaptcha(result.url);
        } else if (result.error) {
            setValid(result.error);
        } else {
            setValid('Máy chủ đăng nhập đang quá tải');
        }
    };

    const handleClose = async () => {
        dispatch(closeModal());
        document.body.classList.remove('open');
    };

    return (
        <Fragment>
            {!captcha ? (
                <div className={cx('wrapper')}>
                    <div className={cx('container')}>
                        {loading ? (
                            <Loading active />
                        ) : (
                            <Fragment>
                                <div className={cx('close')} onClick={handleClose}>
                                    <div className={cx('close-icon')}></div>
                                </div>
                                <div className={cx('heading-bg', 'heading')}>
                                    <img
                                        className={cx('heading-bg')}
                                        alt="Heading"
                                        src="https://cdn-gop.garenanow.com/webmain/static/payment_center/common/login_bg.png"
                                    />
                                    <img
                                        src="https://cdn-gop.garenanow.com/gop/app/0000/100/067/icon.png"
                                        alt="Free Fire"
                                        className={cx('icon')}
                                    />
                                    <div className={cx('ig')}>ID đăng nhập</div>
                                </div>
                                <div className={cx('body')}>
                                    <div className={cx('from')}>
                                        <input
                                            type="text"
                                            className={cx('input')}
                                            placeholder="ID người chơi"
                                            maxLength={64}
                                            value={uid}
                                            onChange={(e) => {
                                                setUid(e.target.value);
                                                valid && setValid('');
                                            }}
                                        />

                                        {valid && (
                                            <div className={cx('error')}>
                                                <div className={cx('message')}>{valid}</div>
                                            </div>
                                        )}

                                        <div className={cx('btn-login')}>
                                            <CusButton onClick={handleLogin} text="Đăng nhập" />
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            ) : (
                <div className={cx('captcha')}>
                    <CaptchaFrame url={captcha} setCaptcha={setCaptcha} />
                </div>
            )}

            <div className={cx('overlay')} onClick={handleClose}></div>
        </Fragment>
    );
}

export default Login;
