import { Fragment } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import FormPay from '../FormPay';
import Notification from '../Notification';
import Membership from '../Mobile/Membership';
import styles from './Transaction.module.scss';
import { postSelectCard } from '~/redux/reducer/card';
import { convertCurrency, convertDiamond } from '~/configs';

const cx = classNames.bind(styles);

function Transaction() {
    const dispatch = useDispatch();
    const { currentCard, selectCard, memberCard } = useSelector((state) => state.card);

    const handleSelect = (value, index) => {
        dispatch(
            postSelectCard({
                select: convertDiamond(value.gift),
                price: value.value,
                status: 1,
                index,
                type: 'C',
            }),
        );
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('pay')}>
                <div className={cx('left')}>
                    <div className={cx('title')}>Thẻ nạp kim cương</div>
                    <div className={cx('header')}>
                        <span className={cx('price')}>Giá</span>
                        <span className={cx('diamond')}>Thêm điểm</span>
                    </div>
                    <ul className={cx('list')}>
                        {currentCard?.value.map((value, index) => (
                            <li
                                className={selectCard?.index === index && selectCard?.status === 1 ? cx('item', 'active') : cx('item')}
                                key={index}
                                onClick={() => handleSelect(value, index)}
                            >
                                <div className={cx('check')}></div>
                                <div>
                                    <div className={cx('value')}>
                                        <span>{convertCurrency(value.value)}</span>
                                    </div>
                                    <div className={cx('value-received')}>Kim cương x {convertDiamond(value.gift)}</div>
                                </div>
                            </li>
                        ))}
                    </ul>

                    {memberCard.length > 0 ? (
                        <Fragment>
                            <div className={cx('title')}>Thẻ thành viên tuần & tháng</div>
                            <Membership />
                        </Fragment>
                    ) : (
                        <Notification>
                            Ưu đãi nhận thêm <b style={{ color: '#FF0000' }}>20%</b> kim cương khi nạp bằng thẻ
                            <b style={{ color: '#FF0000' }}> VIETTEL</b>.
                        </Notification>
                    )}
                </div>

                <div className={cx('line')}></div>

                {selectCard ? (
                    <FormPay />
                ) : (
                    <div className={cx('no-card')}>
                        <div className={cx('no-result')}>
                            <span>Vui lòng chọn mệnh giá ở bên trái để thanh toán</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Transaction;
