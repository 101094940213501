import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import styles from './MobilePay.module.scss';
import { postCurrentCard } from '~/redux/reducer/card';

const cx = classNames.bind(styles);

function MobilePay({ cards }) {
    const dispatch = useDispatch();

    const handleSelect = (card) => {
        if (card.active) {
            dispatch(postCurrentCard(card));
        }
    };

    return (
        <div className={cx('wrapper')}>
            <ul className={cx('list')}>
                {cards.map((card, index) => (
                    <li
                        className={card.active ? cx('item') : cx('item', 'disabled')}
                        key={index}
                        onClick={() => handleSelect(card)}
                    >
                        <Link to={`/app/buy/${card.product_code}`}>
                            <div className={cx('left')}>
                                <div className={cx('image')}>
                                    <img src={card.image_mobile} alt={card.title} />
                                </div>
                                <div className={cx('name')}>{card.title}</div>
                            </div>
                            <div className={cx('right')}>
                                {card.active && <div className={cx('hot')}>Hot</div>}
                                <FontAwesomeIcon icon={faChevronRight} />
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default MobilePay;
