import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HistoryItem from './HistoryItem';
import styles from './History.module.scss';
import Sliders from '~/components/Sliders';
import { getHistory } from '~/services/card';
import CusButton from '~/components/CusButton';
import { logoutSuccess } from '~/redux/reducer/auth';
import { dispatchPostError } from '~/redux/reducer/error';

const cx = classNames.bind(styles);

function History() {
    const [message, setMessage] = useState('');
    const [active, setActive] = useState(false);
    const [histories, setHistories] = useState([]);
    const [accountId, setAccountId] = useState('');

    const { userLogin } = useSelector((state) => state.setting);
    const { currentUser } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (userLogin) {
            if (currentUser) {
                const fetch = async () => {
                    const result = await getHistory('VN', 'vi');

                    if (result.status === 403) {
                        localStorage.removeItem('_re');
                        navigate('/');
                        window.location.reload();
                    } else if (result.status === 401) {
                        dispatch(dispatchPostError(result));
                    } else if (result.status === 200) {
                        setHistories(result.data);
                    } else {
                        dispatch(logoutSuccess());
                        navigate('/app');
                        window.location.reload();
                    }
                };
                fetch();
            } else {
                navigate('/app');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = async () => {
        if (!accountId) {
            return setMessage('Vui lòng nhập ID người chơi cần tìm');
        }

        if (accountId.length < 8 || accountId.length > 11) {
            return setMessage('ID người chơi không hợp lệ');
        }

        const result = await getHistory('VN', 'vi', accountId);

        if (result.status === 403) {
            localStorage.removeItem('_re');
            navigate('/');
            window.location.reload();
        } else if (result.status === 401) {
            dispatch(dispatchPostError(result));
        } else if (result.status === 200) {
            if (result.data.length > 0) {
                setHistories(result.data);
            } else {
                setActive(true);
            }
        } else {
            setMessage(result.error);
        }
    };

    return (
        <div className={cx('wrapper')}>
            <CusButton back />
            <Sliders />

            {!userLogin && (
                <Fragment>
                    <div className={cx('search')}>
                        <input
                            type="text"
                            className={cx('input')}
                            placeholder="Vui lòng nhập ID người chơi cần tìm"
                            value={accountId}
                            onChange={(e) => {
                                setAccountId(e.target.value);
                                setMessage('');
                                setActive(false);
                            }}
                        />
                        <CusButton display text="Tìm" onClick={handleSearch} />
                    </div>
                    <div className={cx('message-error')}>{message}</div>
                </Fragment>
            )}

            <ul className={cx('list')}>
                {histories.length > 0 ? (
                    histories.map((history) => <HistoryItem data={history} key={history._id} userLogin={userLogin} />)
                ) : (
                    <Fragment>
                        {!userLogin && active && (
                            <div className={cx('not')}>
                                <div className={cx('not-image')}></div>
                                <div className={cx('not-mess')}>Không có lịch sử giao dịch</div>
                            </div>
                        )}

                        {userLogin && currentUser && (
                            <div className={cx('not')}>
                                <div className={cx('not-image')}></div>
                                <div className={cx('not-mess')}>Không có lịch sử giao dịch</div>
                            </div>
                        )}
                    </Fragment>
                )}
            </ul>
        </div>
    );
}

export default History;
