import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../Loading';
import ResultPay from '../ResultPay';
import CusButton from '../CusButton';
import styles from './FormPay.module.scss';
import { postCard } from '~/services/card';
import { dispatchPostError } from '~/redux/reducer/error';
import { convertCurrency, isNumericString } from '~/configs';

const cx = classNames.bind(styles);

function FormPay() {
    const [uid, setUid] = useState('');
    const [code, setCode] = useState('');
    const [data, setData] = useState(null);
    const [valid, setValid] = useState('');
    const [serial, setSerial] = useState('');
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);
    const { userLogin } = useSelector((state) => state.setting);
    const { selectCard, currentCard } = useSelector((state) => state.card);

    useEffect(() => {
        if (!selectCard || !currentCard) {
            navigate('/app');
        }
    }, [selectCard, currentCard, navigate]);

    useEffect(() => {
        if (active) {
            setCode('');
            setSerial('');
            setActive(false);
        }

        setValid('');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectCard]);

    let title, image;
    if (selectCard?.status === 2) {
        title = '';
        image = 'https://i.imgur.com/rsx1TLM.png';
    } else {
        image = 'https://cdn-gop.garenanow.com/gop/app/0000/100/067/point.png';
        title = 'Kim cương ×';
    }

    const handleConfirm = async () => {
        if (!userLogin) {
            if (uid.length < 8 || uid.length > 11 || !isNumericString(uid)) {
                return setValid('ID người chơi không hợp lệ');
            }
        }
        if (!serial.trim()) {
            return setValid('Vui lòng nhập serial thẻ');
        }
        if (!isNumericString(serial)) {
            return setValid('Serial thẻ không đúng định dạng');
        }

        if (!code.trim()) {
            return setValid('Vui lòng nhập mã thẻ');
        }
        if (!isNumericString(code)) {
            return setValid('Mã thẻ không đúng định dạng');
        }

        setLoading(true);
        let card = {
            type: selectCard?.type,
            telco: currentCard?.product_code,
            amount: selectCard?.price,
            code: code.trim(),
            serial: serial.trim(),
        };
        if (!userLogin) {
            card.account_id = uid;
        } else {
            card.account_id = currentUser.account_id;
        }

        const result = await postCard(card);

        setLoading(false);
        if (result.error) {
            return setValid(result.error);
        }

        if (result.status === 403) {
            localStorage.removeItem('_re');
            navigate('/');
            window.location.reload();
        } else if (result.status === 401) {
            dispatch(dispatchPostError(result));
        }

        const { amount, date, telco, transId } = result.data;
        if (result.status === 99) {
            setActive(true);
            setData({
                select: selectCard?.select,
                message: 'Đang được xử lý!',
                amount,
                telco,
                status: 99,
                date,
                transId,
                image,
                title,
            });
        } else if (result.status === 1) {
            setActive(true);
            setData({
                select: selectCard?.select,
                message: 'Giao dịch thành công',
                amount,
                telco,
                status: 1,
                date,
                transId,
                image,
                title,
            });
        } else if (result.status === 2) {
            setActive(true);
            setData({
                select: selectCard?.select,
                message: 'Thẻ sai mệnh giá',
                amount,
                telco,
                status: 2,
                date,
                transId,
                title,
                image,
            });
        } else {
            setActive(true);
            setData({
                select: selectCard?.select,
                message: 'Thẻ cào sai',
                amount,
                telco,
                status: 3,
                date,
                transId,
                title,
                image,
            });
        }
    };

    return (
        <div className={cx('wrapper')}>
            {loading && !active && <Loading height="50" />}

            {!loading && !active && (
                <div className={cx('container')}>
                    <div className={cx('title')}>Chi tiết giao dịch</div>

                    <div className={cx('content')}>
                        <div className={selectCard?.status === 2 ? cx('line', 'active') : cx('line')}>
                            <div className={cx('product')}>Sản phẩm được chọn</div>

                            <div className={cx('value')}>
                                <img src={image} alt="" />
                                <span>
                                    {title} {selectCard?.select}
                                </span>
                            </div>
                        </div>
                        {selectCard?.status === 2 && (
                            <Fragment>
                                <div className={cx('description')}>{selectCard?.description}</div>
                                <div className={cx('line')}></div>
                            </Fragment>
                        )}
                        <div className={cx('line')}>
                            <div className={cx('product')}>Giá</div>

                            <div className={cx('value')}>{convertCurrency(selectCard?.price)}</div>
                        </div>
                        <div className={cx('line')}>
                            <div className={cx('product')}>Phương thức thanh toán</div>
                            <div className={cx('value')}>{currentCard?.title}</div>
                        </div>
                        {!userLogin ? (
                            <Fragment>
                                <div className={cx('line', 'active')}>
                                    <div className={cx('product')}>ID người chơi</div>
                                </div>
                                <div className={cx('code')}>
                                    <input
                                        type="text"
                                        placeholder="Nhập ID người chơi"
                                        value={uid}
                                        onChange={(e) => {
                                            setUid(e.target.value);
                                            setValid('');
                                        }}
                                    />
                                </div>
                            </Fragment>
                        ) : (
                            <div className={cx('line')}>
                                <div className={cx('product')}>Tên tài khoản trong game</div>
                                <div className={cx('value')}>{currentUser?.nickname}</div>
                            </div>
                        )}
                        <div className={cx('line', 'active')}>
                            <div className={cx('product')}>Serial nạp thẻ</div>
                        </div>
                        <div className={cx('code')}>
                            <input
                                type="text"
                                placeholder="Nhập serial thẻ của bạn"
                                value={serial}
                                onChange={(e) => {
                                    setSerial(e.target.value);
                                    setValid('');
                                }}
                            />
                        </div>
                        <div className={cx('line', 'active')}>
                            <div className={cx('product')}>Mã nạp thẻ</div>
                        </div>
                        <div className={cx('code')}>
                            <input
                                type="text"
                                placeholder="Nhập mã thẻ của bạn"
                                value={code}
                                onChange={(e) => {
                                    setCode(e.target.value);
                                    setValid('');
                                }}
                            />
                        </div>
                        {valid && <div className={cx('valid')}>{valid}</div>}
                        <div className={cx('btn-confirm')}>
                            <CusButton onClick={handleConfirm} text="Xác nhận" />
                        </div>
                    </div>
                </div>
            )}

            {active && !loading && <ResultPay data={data} />}
        </div>
    );
}

export default FormPay;
