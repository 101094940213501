import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import styles from './GameIcon.module.scss';

const cx = classNames.bind(styles);

function GameIcon({ icon = null }) {
    const { currentCard } = useSelector((state) => state.card);

    return (
        <div className={cx('wrapper')}>
            <div className={cx('icon')}></div>
            <div className={cx('title')}>
                <div className={cx('game')}>Free Fire</div>
                <div className={cx('telco')}>{icon || currentCard?.telco}</div>
            </div>
        </div>
    );
}

export default GameIcon;
