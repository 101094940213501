import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

function Footer() {
    return (
        <footer className={cx('wrapper')}>
            <div className={cx('container')}>
                <div className={cx('content')}>
                    CÔNG TY CỔ PHẦN PHÁT TRIỂN THỂ THAO ĐIỆN TỬ VIỆT NAM <br /> Giấy CNĐKKD số 0103959912, cấp lần đầu
                    ngày 09/06/2009 | Cơ quan cấp: Phòng Đăng ký kinh doanh- Sở Kế hoạch và đầu tư TP Hà Nội <br /> Địa
                    chỉ trụ sở chính: Tầng 29, Tòa nhà Trung tâm Lotte Hà Nội, số 54 đường Liễu Giai, Phường Cống Vị,
                    Quận Ba Đình, TP Hà Nội, Việt Nam Điện thoại: 024 73053939
                </div>
                <div className={cx('list')}>
                    <div className={cx('item')}>
                        <Link to="/faq">Câu hỏi thường gặp</Link>
                    </div>
                    <div className={cx('item')}>
                        <Link to="/terms">Câu hỏi thường gặp</Link>
                    </div>
                    <div className={cx('item')}>
                        <Link to="/privacy">Câu hỏi thường gặp</Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
