import request from '~/utils';

export const getSliders = async (region, language) => {
    try {
        const res = await request.get('/players/banners', {
            params: {
                region,
                language,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
