import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './LoadHistory.module.scss';
import LoadHistoryItem from './LoadHistoryItem';

const cx = classNames.bind(styles);

function LoadHistory() {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('header')}>
                <h3 className={cx('title')}>Lịch sử nạp người dùng</h3>
                <Link to="/histories">Xem lịch sử của bạn</Link>
            </div>
            <div className={cx('container')}>
                <div className={cx('table')}>
                    <LoadHistoryItem />
                </div>
            </div>
        </div>
    );
}

export default LoadHistory;
