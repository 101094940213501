export const convertCurrency = (number) => {
    return number ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'VND' }).format(number) : '0 VND';
};

export const convertDiamond = (number) => {
    return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0;
};

export const isNumericString = (input) => {
    return /^[0-9]+$/.test(input);
};
