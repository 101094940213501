import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';

import { convertCurrency } from '~/configs';
import styles from './Membership.module.scss';
import { postSelectCard } from '~/redux/reducer/card';

const cx = classNames.bind(styles);

function Membership() {
    const dispatch = useDispatch();
    const { memberCard, selectCard } = useSelector((state) => state.card);

    const handleSelect = (card, index) => {
        dispatch(
            postSelectCard({
                select: card.title,
                price: card.price,
                description: card.description,
                status: 2,
                index,
                type: 'T',
            })
        );
    };

    const Wrapper = isMobile ? Link : 'div';

    return (
        <ul className={cx('wrapper')}>
            {memberCard?.map((memberCard, index) => (
                <li className={cx('item')} key={index} onClick={() => handleSelect(memberCard, index)}>
                    <Wrapper to="/app/buy/pay">
                        <div className={cx('image')}>
                            <div
                                className={
                                    selectCard?.index === index && selectCard?.status === 2
                                        ? cx('border', 'active')
                                        : cx('border')
                                }
                            ></div>
                            <img src={memberCard.image_url} alt={memberCard.title} />
                            <div className={cx('discount')}>
                                <span>Hot</span>
                            </div>
                        </div>

                        <div
                            className={
                                selectCard?.index === index && selectCard?.status === 2
                                    ? cx('name', 'active')
                                    : cx('name')
                            }
                        >
                            {memberCard.title}
                        </div>
                        <div className={cx('price')}>{convertCurrency(memberCard.price)}</div>
                        {memberCard.discount > 0 && <div className={cx('old-price')}>{memberCard.old_price}</div>}
                    </Wrapper>
                </li>
            ))}
        </ul>
    );
}

export default Membership;
