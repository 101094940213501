import request from '~/utils';

export const getChannels = async (source) => {
    try {
        const res = await request.get('/players/channels', {
            params: {
                region: 'VN',
                language: 'vi',
                source,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const postCard = async (card) => {
    try {
        const res = await request.post('/chargingws/v2', card);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const getTelco = async (telco) => {
    try {
        const res = await request.get('/players/telco', {
            params: {
                telco,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const getHistory = async (region, language, account_id = null) => {
    try {
        const res = await request.get('/players/histories', {
            params: {
                region,
                language,
                account_id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
