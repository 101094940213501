import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import styles from './FreeFire.module.scss';
import { openModal } from '~/redux/reducer/module';

const cx = classNames.bind(styles);

function FreeFire() {
    const dispatch = useDispatch();

    const handleOpen = () => {
        dispatch(openModal());
        document.body.classList.add('open');
    };

    return (
        <ul className={cx('wrapper')}>
            <li className={cx('item')}>
                <div className={cx('box')} onClick={handleOpen}>
                    <div className={cx('image')}>
                        <img src="https://cdn-gop.garenanow.com/gop/app/0000/100/067/icon.png" alt="Free Fire" />
                    </div>
                    <h2 className={cx('name')}>Free Fire</h2>
                </div>
            </li>
        </ul>
    );
}

export default FreeFire;
