import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import styles from './Sliders.module.scss';
import 'slick-carousel/slick/slick-theme.css';

const cx = classNames.bind(styles);

const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 6000,
};

function Sliders() {
    const { sliders } = useSelector((state) => state.slider);

    return (
        <div className={cx('wrapper')}>
            <Slider {...settings}>
                {sliders?.map((slider, index) => (
                    <div key={index}>
                        <a href={slider.link_url} target="_blank" rel="noreferrer">
                            <img src={slider.image_url} alt={slider.slide_name} />
                        </a>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default Sliders;
