import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useNavigate, useParams } from 'react-router-dom';

import styles from './Telco.module.scss';
import { getTelco } from '~/services/card';
import GameIcon from '~/components/Mobile/GameIcon';
import { postSelectCard } from '~/redux/reducer/card';
import Membership from '~/components/Mobile/Membership';
import { dispatchPostError } from '~/redux/reducer/error';
import { convertCurrency, convertDiamond } from '~/configs';

const cx = classNames.bind(styles);

function Telco() {
    const [card, setCard] = useState(null);

    const { telco } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (telco) {
            const fetch = async () => {
                const result = await getTelco(telco);

                if (result.status === 403) {
                    localStorage.removeItem('_re');
                    navigate('/');
                    window.location.reload();
                } else if (result.status === 401) {
                    dispatch(dispatchPostError(result));
                } else if (result.status === 200) {
                    setCard(result.data);
                } else {
                    navigate('/app');
                }
            };
            fetch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [telco]);

    useEffect(() => {
        if (!isMobile) {
            navigate('/app');
        }
    }, [navigate]);

    const handleSelect = (telco, index) => {
        dispatch(
            postSelectCard({
                select: convertDiamond(telco.gift),
                price: telco.value,
                status: 1,
                index,
                type: 'C',
            }),
        );
    };

    return (
        <div className={cx('wrapper')}>
            <GameIcon icon={card?.title} />

            <div className={cx('help')}>Chọn gói cần nạp</div>

            <ul className={cx('list')}>
                {card?.value.map((telco, index) => (
                    <li className={cx('item')} key={index} onClick={() => handleSelect(telco, index)}>
                        <Link to="/app/buy/pay">
                            <div className={cx('value')}>
                                <div className={cx('icon-diamond')}>
                                    <img
                                        src="https://cdn-gop.garenanow.com/gop/app/0000/100/067/point.png"
                                        alt="Kim cương"
                                    />
                                </div>
                                <div className={cx('diamond')}>
                                    <div className={cx('diamond-title')}>Kim Cương</div>
                                    <div className={cx('diamond-value')}>{convertDiamond(telco.gift)}</div>
                                </div>
                            </div>
                            <div className={cx('face-value')}>{convertCurrency(telco.value)}</div>
                        </Link>
                    </li>
                ))}
            </ul>

            <Membership />
        </div>
    );
}

export default Telco;
