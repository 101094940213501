import moment from 'moment';
import classNames from 'classnames/bind';

import styles from './HistoryItem.module.scss';
import { convertCurrency, convertDiamond } from '~/configs';

const cx = classNames.bind(styles);

function HistoryItem({ data, userLogin }) {
    return (
        <li className={cx('wrapper')}>
            <div className={cx('product')}>
                <div>
                    <img
                        src={
                            data.type === 'T'
                                ? 'https://i.imgur.com/rsx1TLM.png'
                                : 'https://cdn-gop.garenanow.com/gop/app/0000/100/067/point.png'
                        }
                        alt={data.type === 'T' ? 'Thẻ tuần' : 'Kim cương'}
                    />
                    <span>
                        {data.type === 'T' ? 'Thẻ tuần' : 'Kim cương'} × {convertDiamond(data.gift)}
                    </span>
                </div>
                <div className={cx('date')}>{moment(data.date).format('HH:mm:ss DD/MM/YYYY')}</div>
                <div className={cx('date')}>
                    {userLogin ? `Nickname: ${data.nickname}` : `Game ID: ${data.account_id}`}
                </div>
            </div>
            <div className={cx('value')}>
                <span>{convertCurrency(data.price)}</span>
                <div className={cx('date')}>{data.telco}</div>
                <div
                    className={cx(
                        'result',
                        data.status === 99 ||
                            data.status === 101 ||
                            data.status === 102 ||
                            data.status === 103 ||
                            data.status === 104
                            ? 'warning'
                            : 'danger',
                    )}
                >
                    {data.status === 99 ||
                    data.status === 101 ||
                    data.status === 102 ||
                    data.status === 103 ||
                    data.status === 104
                        ? 'Chờ sử lý'
                        : data.status === 2
                        ? 'Sai mệnh giá'
                        : data.status === 1
                        ? 'Lỗi hệ thống'
                        : 'Thẻ cào sai'}
                </div>
            </div>
        </li>
    );
}

export default HistoryItem;
