import { Fragment } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import styles from './Home.module.scss';
import PayCard from '~/components/PayCard';
import Sliders from '~/components/Sliders';
import FreeFire from '~/components/FreeFire';
import LoadHistory from '~/components/LoadHistory';
import freeFire from '~/assets/image/free-fire.png';
import Notification from '~/components/Notification';

const cx = classNames.bind(styles);

function Home() {
    const { currentUser } = useSelector((state) => state.auth);
    const { userLogin, history_charging } = useSelector((state) => state.setting);

    return (
        <div className={cx('wrapper')}>
            <div className={cx('container')}>
                <Sliders />

                <div className={cx('border')}>
                    <div className={!userLogin || currentUser ? cx('line') : cx('line', 'active')}></div>
                    {!userLogin || currentUser ? (
                        <Fragment>
                            <img src={freeFire} alt="Free Fire" />
                            <h1 className={cx('game')}>Chọn phương thức thanh toán</h1>
                            <div className={cx('reload')} onClick={() => window.location.reload()}>
                                <span className={cx('icon-reload')}></span>
                                <span className={cx('text-reload')}>Làm mới</span>
                            </div>
                        </Fragment>
                    ) : (
                        <div className={cx('login')}>Đăng nhập để nạp</div>
                    )}
                    <div className={!userLogin || currentUser ? cx('line') : cx('line', 'active')}></div>
                </div>
                {!userLogin || currentUser ? (
                    <Notification>
                        Nhận ngay <b style={{ color: '#FF0000' }}>AK47 RỒNG XANH</b> khi nạp thẻ có mệnh giá từ
                        <b style={{ color: '#FF0000' }}> 200 000 VND</b> trở lên.
                    </Notification>
                ) : (
                    <Fragment></Fragment>
                )}

                {!userLogin || currentUser ? <PayCard /> : <FreeFire />}

                {history_charging && <LoadHistory />}
            </div>
        </div>
    );
}

export default Home;
