import { useEffect } from 'react';

const CaptchaFrame = ({ url, setCaptcha }) => {
    useEffect(() => {
        const handleMessage = (event) => {
            if (event.origin === 'https://geo.captcha-delivery.com') {
                const cookieParser = JSON.parse(event.data);
                const cookie = cookieParser.cookie;

                if (cookie) {
                    document.cookie = cookie;
                    setCaptcha('');
                }
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <iframe src={url} width="100%" height="500px" title="Captcha" />
        </div>
    );
};

export default CaptchaFrame;
